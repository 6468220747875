import "../../App.css";

import React, {
  useState,
  useEffect,
  useCallback,
  createRef,
  useRef,
} from "react";

import Footer1 from "../Footer1";
import "./DevopsServices.css";
import "./Homepage.css";

function DevopsServices() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div>
      <div
        className="tech-stack"
        style={{ maxHeight: "70px", padding: "2rem" }}
      >
        <h1 className="main-banner-h1" style={{ fontSize: "25px" }}>
          Burn Down Your Devops And Cloud Backlogs with Qualitek Experts
        </h1>
      </div>
      <div
        style={{
          backgroundImage:
            "url(" + require("../../Images/devopsservice-banner-img.jpg") + ")",
          backgroundSize: "100% 100%",
          resize: "both",
          opacity: 1,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          height: "600px",
          width: "100%",
        }}
      >
        <div
          className="end-banner"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            height: "600px",
            width: "100%",
            opacity: 0.8,
          }}
        >
          <h2
            style={{
              fontSize: "2.5vw",
              width: "100%",
              color: "#fff",
              opacity: 1,
              textAlign: "center",
              zIndex: 2,
            }}
          >
            IMPROVE YOUR EFFICIENCY ON THE CLOUD. ANY CLOUD.
          </h2>

          <h2
            style={{
              fontSize: "1.5vw",
              width: "100%",
              color: "#feefde",
              opacity: 1,
              textAlign: "center",
            }}
          >
            Streamline your infrastructure automation with tools like Terraform
            and automate your DevOps CI/CD pipelines with our DevOps framework.
          </h2>

          <div
            style={{
              backgroundColor: "white",
              width: "50%",
              justifyContent: "flex-end",
              opacity: 1,
              borderRadius: 15,
              marginTop: "3%",
            }}
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <h2
              style={{
                display: "flex",
                borderWidth: 1,
                borderColor: "#feefde",

                minHeight: 60,
                aligSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                color: "rgb(12, 27, 75)",
              }}
            >
              Request A Consultation With Our Experts
            </h2>
          </div>
        </div>
      </div>

      <div
        className="tech-stack"
        style={{
          minHeight: "800px",
          padding: "2rem",
          display: "flex",
          flexDirection: "row",
          minWidth: "40%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundImage:
              "url(" + require("../../Images/devops-consulting-img.gif") + ")",
            backgroundSize: "100% 100%",
            resize: "both",
            opacity: 1,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "600px",
            width: "500px",
          }}
        ></div>
        <div
          style={{
            minWidth: "50%",
            minHeight: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <h2 className="devops-consulting-banner-head">
            Succeed on the cloud together
          </h2>
          <ul className="devops-consulting-banner-div-body">
            <li className="devops-consulting-banner-list-item">
              - Build high-performance, scalable and agile technology on modern,
              cloud-first IT environments
            </li>
            <li className="devops-consulting-banner-list-item">
              - Integrate and synchronize your development and operations teams
            </li>
            <li className="devops-consulting-banner-list-item">
              - Eliminate silos and bring all stakeholders across functions onto
              the same page
            </li>
            <li className="devops-consulting-banner-list-item">
              - Improve time to market, achieve rapid yet consistent delivery,
              and enhance customer experience
            </li>
          </ul>
        </div>
      </div>

      <div className="managed-services">
        <div
          class=""
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 class="banner-head">Industry Leading DevOps Services</h2>
          <p class="banner-p">
            We understand DevOps in the context of your business and help you
            leverage this approach for optimal results with single click
            deployment, continuous integration, automated security alerts, easy
            code management and review, and on-premise, public or hybrid cloud
            deployment.
          </p>
        </div>
        <div class="services-card">
          <div class="services-card-row">
            <div class="services-card-inner">
              <img
                src={require("../../Images/devops-img-1.png")}
                alt="cloud-comp"
                class="img-services-card"
              />

              <div class="services-card-data">
                <div class="services-card-data-head">
                  <h4>DevOps Implementation Services</h4>
                </div>

                <p class="services-card-data-body">
                  Outsource you DevOps implementation to apply automation and
                  build automated Continuous Integration (CI), Continuous
                  Testing (CT), and Continuous Deployment (CD) pipelines. DevOps
                  as a Service can take the load off your tech teams, and allow
                  them to devote their time to solving business problems.
                </p>
              </div>
            </div>

            <div class="services-card-inner">
              <img
                src={require("../../Images/devops-img-2.png")}
                alt="cloud-comp"
                class="img-services-card"
              />
              <div class="services-card-data">
                <div class="services-card-data-head">
                  <h4>DevOps Consulting Services</h4>
                </div>

                <p class="">
                  Foster a culture of collaboration to achieve faster
                  application releases with improved build quality - assessment,
                  strategizing, designing the roadmap, and implementing your
                  automated deployment pipelines.we deliver digital
                  transformationsons on AWS, GCP and Azure alongside the world’s
                  most Innovative Companies.
                </p>
              </div>
            </div>
          </div>

          <div class="services-card-row">
            <div class="services-card-inner">
              <img
                src={require("../../Images/devops-img-3.png")}
                alt="cloud-comp"
                class="img-services-card"
              />
              <div class="services-card-data">
                <div class="services-card-data-head">
                  <h4>App Containerization Services</h4>
                </div>

                <p class="">
                  Containerization has measurable benefits for both developers
                  and IT operations when it comes to creating and deploying
                  applications swiftly and securely. Our expert team consults,
                  configures, and orchestrates containerization of applications
                  though popular technologies like Kubernetes for lightweight,
                  flexible, agile application development, monitoring, and
                  maintenance.
                </p>
              </div>
            </div>

            <div class="services-card-inner">
              <img
                src={require("../../Images/devops-img-4.png")}
                alt="cloud-comp"
                class="img-services-card"
              />
              <div class="services-card-data">
                <div class="services-card-data-head">
                  <h4>Cloud Infrastructure Services</h4>
                </div>

                <p class="">
                  Work with us to manage configuration, provisioning,
                  deprovisioning and scaling using Infrastructure as Code (IaC)
                  with Terraform. Gain comprehensive visibility into your
                  hybrid, cloud, or multi-cloud environment for more effective
                  management, resilient infrastructure, real-time analytics,
                  efficient resource management, reinforced security, and
                  stronger performance.
                </p>
              </div>
            </div>
          </div>

          <div class="services-card-row">
            <div class="services-card-inner">
              <img
                src={require("../../Images/devops-img-5.png")}
                alt="cloud-comp"
                class="img-services-card"
              />
              <div class="services-card-data">
                <div class="services-card-data-head">
                  <h4>Azure DevOps Services</h4>
                </div>

                <p class="">
                  Build and deploy applications faster with our expertise in the
                  Azure DevOps platform. Fully tap into the capabilities of
                  Azure DevOps and its features for source control, continuous
                  integration (CI) / continuous deployment (CD), testing,
                  project management, and more with our certified Azure
                  engineers.
                </p>
              </div>
            </div>

            <div class="services-card-inner">
              <img
                src={require("../../Images/devops-img-6.png")}
                alt="cloud-comp"
                class="img-services-card"
              />
              <div class="services-card-data">
                <div class="services-card-data-head">
                  <h4>AWS DevOps Services</h4>
                </div>

                <p class="">
                  Harness the services provided by Amazon Web Services (AWS) to
                  accelerate development and delivery of applications through
                  simplified infrastructure provisioning and management, code
                  deployment, release automation, and performance monitoring.
                  Embrace automation and manage complex infrastructure
                  environments with our certified AWS engineers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tech-stack">
        <div
          className="tech-stack-banner"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h2
            className="tech-stack-head"
            style={{ margin: "1%", marginLeft: "3%" }}
          >
            Our Implementation Methodology
          </h2>
          <h3
            className="tech-stack-head"
            style={{ margin: "1%", marginLeft: "3%", fontSize: "30px" }}
          >
            Step By Step Devops Strategy
          </h3>
          <div
            style={{
              height: "5%",
              width: "80%",
              backgroundSize: "100% 100%",

              marginLeft: "-10%",
              resize: "both",
            }}
          >
            <img
              src={require("../../Images/devops-implementation-img-1.jpg")}
              alt="cloud-comp"
              style={{
                width: "75%",
                objectFit: "contain",
                resize: "both",
              }}
            />
          </div>
        </div>
        <div className="tech-stack-banner">
          <h2 className="tech-stack-head">Our Tech Stack</h2>
        </div>
        <div className="tech-stack-container">
          <div className="tech-stack-col">
            <div className="tech-stack-img-div">
              <img
                src={require("../../Images/tech-stack-img-2.png")}
                alt="cloud-comp"
              />
            </div>
            <div className="tech-stack-box">
              <p className="tech-stack-p">
                Integration
                <br></br>& <br></br>CodeManagement
              </p>
            </div>
          </div>
          <div className="tech-stack-col">
            <div className="tech-stack-img-div">
              <img
                src={require("../../Images/tech-stack-img-1.png")}
                alt="cloud-comp"
              />
            </div>
            <div className="tech-stack-box">
              <p className="tech-stack-p">Testing</p>
            </div>
          </div>
          <div className="tech-stack-col">
            <div className="tech-stack-img-div">
              <img
                src={require("../../Images/tech-stack-img-3.png")}
                alt="cloud-comp"
              />
            </div>
            <div className="tech-stack-box">
              <p className="tech-stack-p">
                Build
                <br></br>& <br></br>MicroServices
              </p>
            </div>
          </div>
          <div className="tech-stack-col">
            <div className="tech-stack-img-div">
              <img
                src={require("../../Images/tech-stack-img-4.png")}
                alt="cloud-comp"
              />
            </div>
            <div className="tech-stack-box">
              <p className="tech-stack-p">
                Configuration
                <br></br>& <br></br>Cloud Platforms
              </p>
            </div>
          </div>
          <div className="tech-stack-col">
            <div className="tech-stack-img-div">
              <img
                src={require("../../Images/tech-stack-img-5.png")}
                alt="cloud-comp"
              />
            </div>
            <div className="tech-stack-box">
              <p className="tech-stack-p">
                Monitoring
                <br></br>& <br></br>Communication
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner">
        <div className="end-banner-container">
          <h5>
            Ready to save Time, Budget and Resources with a devops driven
            solution?
          </h5>

          <div className="end-banner-btn">
            <h2>Let Us Talk</h2>
          </div>
        </div>

        <div ref={footerRef} key="footerId" className="footerBox">
          <Footer1 />
        </div>
      </div>

      <div
        style={{ height: "500px", width: "100%", backgroundColor: "white" }}
      ></div>
    </div>
  );
}

export default DevopsServices;
