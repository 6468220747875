import React, { useState } from "react";
//import { serviceDropdown } from "./NavItems";
import { Link, NavLink, BrowserRouter } from "react-router-dom";
import "./Dropdown.css";

function Dropdown() {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className="dropdown-container-1">
      <ul
        className={dropdown ? "services-submenu clicked" : "services-submenu"}
        onClick={() => setDropdown(!dropdown)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <div className="dropdown-row">
            <div className="dropdown-row-box">
              <p className="submenuHeader">Functional</p>

              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./testautomation"}
                    className="submenuItem"
                    onClick={() => {
                      setDropdown(false);
                    }}
                  >
                    Test Automation
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./manualtesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Manual Testing
                  </Link>
                </div>
              </li>

              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./mobileapptesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Mobile App Testing
                  </Link>
                </div>
              </li>
            </div>

            <div className="dropdown-row-box">
              <p className="submenuHeader">NextGen</p>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./aitesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    AI Testing
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./rpatesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    RPA Testing
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./iottesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    IoT Testing
                  </Link>
                </div>
              </li>
            </div>
            <div className="dropdown-row-box">
              <p className="submenuHeader">Test Consulting</p>

              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./testadvisory"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Test Advisory
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./tcoesetup"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    TCoE Setup
                  </Link>
                </div>
              </li>
            </div>
          </div>
          <div className="dropdown-row" style={{ marginBottom: "1.2%" }}>
            <div className="dropdown-row-box">
              <p className="submenuHeader">Non Functional</p>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./performancetesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Performance Testing
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./securitytesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    SecurityTesting
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./usabilitytesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Usability Testing
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./accessibilitytesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Accessibility Testing
                  </Link>
                </div>
              </li>
            </div>

            <div className="dropdown-row-box">
              <p className="submenuHeader">Specialized</p>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./apimicroservicestesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    API & Micro Services Testing
                  </Link>
                </div>
              </li>

              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./eastesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    EAS Testing
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./testdatamanagement"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Test data Management
                  </Link>
                </div>
              </li>

              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./datawarehousingtesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Datawarehouse Testing
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./datamigrationtesting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Data Migration Testing
                  </Link>
                </div>
              </li>
            </div>
            <div className="dropdown-row-box">
              <p className="submenuHeader">DevOps</p>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./devops-consulting"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    DevOps Consulting
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./devops-transformation"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    Devops Transformation
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./devops-implementation"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    IAC Consulting
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./testautomation-in-devops"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    DevOps Automation
                  </Link>
                </div>
              </li>
              <li key={1}>
                <div className="submenu-item-box">
                  <Link
                    to={"./devops-ci-cd-implementations"}
                    className="submenuItem"
                    onClick={() => setDropdown(false)}
                  >
                    DevOps CI/CD
                  </Link>
                </div>
              </li>
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
}

export default Dropdown;
