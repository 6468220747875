import React, { useState } from "react";
import { useEffect } from "react";

export default function BoxAlphabets(props) {
  console.log("box aplhabets: ");
  console.log(props);

  const [buildBoxDivString, setBuildBoxDivString] = useState([]);

  const buildBoxLetter = (letterObj, letterWidth) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 100,
          width: letterWidth,
          alignItems: "center",
          backgroundColor: letterObj["backgroundcolor"],
        }}
      >
        <span
          style={{
            flex: 70,
            color: letterObj["keycolor"],
            fontSize: 0.85 * letterObj["fontsize"] + "vw",
          }}
        >
          {letterObj["key"]}
        </span>
        <span
          style={{
            flex: 30,
            color: letterObj["wordcolor"],
            fontSize: 0.15 * letterObj["fontsize"] + "vw",
            textAlign: "center",
            paddingBottom: "1vw",
          }}
        >
          {letterObj["word"]}
        </span>
      </div>
    );
  };

  const buildBoxText = (tempArray) => {
    var elementArray = [];
    console.log("in buildboxtext firstline: ");
    console.log(tempArray);
    console.log("length" + tempArray.length);
    var letterWidth = Math.floor(100 / tempArray.length) + "%";
    for (var i = 0; i < tempArray.length; i++) {
      console.log("in buildboxtext: ");
      console.log(tempArray[i]);
      elementArray.push(buildBoxLetter(tempArray[i], letterWidth));
    }

    return elementArray;
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
      {buildBoxText(props.hello)}
    </div>
  );
}
